.@{plugin_prefix}support-info-container {

}

.@{plugin_prefix}update-data-link,
.@{plugin_prefix}base-currency-link {
  &,
  &:focus,
  &:visited,
  &:hover,
  &:visited:hover {
    color: inherit !important;
    text-decoration: none !important;
    border-bottom: 0 !important;
    cursor: text !important;
  }
}
