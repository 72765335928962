
// Default flexbox components

.@{plugin_prefix}f-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.@{plugin_prefix}f-col {
  position: relative;
  min-height: 1px;
}


// Modifications

.@{plugin_prefix}f-row-hor-justify {
  justify-content: space-between;
}

.@{plugin_prefix}f-row-ver-center {
  align-items: center;
}
