.widget_currencyconverter_minimalistic_ver2 {
  .@{plugin_prefix}support-info-container {
    margin-top: 1em;
  }
}

.@{plugin_prefix}minimalistic-ver2-container {
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif !important;
  border: 1px solid rgba(0, 0, 0, .1);
}

.@{plugin_prefix}minimalistic-ver2-header {
  padding: 10px 11px 14px 11px;
  line-height: 1;
}

.@{plugin_prefix}minimalistic-ver2-header-base-currency {
  font-size: 35px;
  font-weight: 300;
  display: block;
}

.@{plugin_prefix}minimalistic-ver2-header-currency-caption {
  display: block;
  font-size: 12px;
  margin-top: 7px;
}

.@{plugin_prefix}minimalistic-ver2-header-equal {
  height: 1px;
  line-height: 1;

  flex-wrap: nowrap;

  .@{plugin_prefix}f-col-dash-1,
  .@{plugin_prefix}f-col-dash-2 {
    span {
      display: block;
    }
  }

  .@{plugin_prefix}f-col-dash-1 {
    flex-basis: (11px - 4px);
  }

  .@{plugin_prefix}f-col-equal {
    padding: 0 4px;
  }

  .@{plugin_prefix}f-col-dash-2 {
    flex-basis: 100%;
  }
}

.@{plugin_prefix}minimalistic-ver2-separator-dash {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, .1);
}

.@{plugin_prefix}minimalistic-ver2-single-currency {
  padding: 12px 11px;
  line-height: 1;

  &:first-child {
    border-top: 0;
  }
}

.@{plugin_prefix}minimalistic-ver2-row {
  display: block;
}

.@{plugin_prefix}minimalistic-ver2-row-captions {
  margin-top: 8px;
}

.@{plugin_prefix}minimalistic-ver2-currency-price {
  font-size: 20px;
  line-height: 1;
  font-weight: 300;
}

.@{plugin_prefix}minimalistic-ver2-inline-list {
  display: block;
  margin-right: -6px;
}

.@{plugin_prefix}minimalistic-ver2-inline-list-item {
  display: inline-block;
  margin-right: 6px;

  font-size: 12px;
  line-height: 1;
  font-weight: 400;
}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}