.widget_currencyconverter_table {

  table {
    tr {
      vertical-align: top;

      td {
        line-height: 1.3;
      }
    }
  }

  .@{plugin_prefix}per {
    display: block;
    line-height: 1;
    font-size: .8em;
    margin-top: .2em;
    opacity: .7;
  }
}
