.widget_currencyconverter_minimalistic {
  .@{plugin_prefix}support-info-container {
    margin-top: 1em;
  }
}

.@{plugin_prefix}minimalistic-container {
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif !important;
  border: 1px solid rgba(0, 0, 0, .1);
  //#gradient > .vertical(#ffa200, #ff5a00);
}

.@{plugin_prefix}minimalistic-single-currency {
  padding: 9px 15px 12px 15px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, .1);

  &:first-child {
    border-top: 0;
  }
}

.@{plugin_prefix}minimalistic-row {
  display: block;
}

.@{plugin_prefix}minimalistic-currency-price {
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
}

.@{plugin_prefix}minimalistic-inline-list {
  display: block;
  margin-top: 6px;
  margin-right: -6px;
}

.@{plugin_prefix}minimalistic-inline-list-item {
  display: inline-block;
  margin-right: 6px;

  font-size: 13px;
  line-height: 1;
  font-weight: 400;
}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}

.@{plugin_prefix} {

}